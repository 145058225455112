.App {
  width: 100%;
  height: 100%;
  background-image: url('../public/background-image-2.png');
  background-size: cover;
  background-position: top;
}

@media screen and (min-width: 1669px) {
  .App {
    background-image: url('../public/background-image-2.png');
  }
}


@media screen and (max-width: 1668px) {
  .App {
    background-image: url('../public/background-image-3.png');
  }
}

@media screen and (max-width: 1250px) {
  .App {
    background-image: url('../public/background-image-1250.png');
  }
}

@media screen and (max-width: 1000px) {
  .App {
    background-image: url('../public/background-image-4.png');
  }
}

.App__content {
  width: 100vw;
  height: 100vh;

  .rcw-conversation-container > .rcw-header {
    background-color: #0D5068;
  }

  .rcw-launcher {
    background-color: #4CC28B;
  }

  .rcw-client .rcw-message-text {
    background-color: #B6CAD2;
  }

  .rcw-picker-btn {
    display: none;
  }

  .rcw-new-message {
    width: calc(100% - 47px);
  }

  .rcw-input {
    cursor: text;
  }
}
